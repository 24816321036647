<template>
  <div style="height: 90vh;">
    <!-- <sdPageHeader title="Wizards"> </sdPageHeader> -->
    <Modal v-show="true">
      <template v-slot:body>
        <div class="onboarding_popup">
          <a-row :gutter="25">
            <a-col :xs="24">
              <WizardBlock>
                <sdCards headless>
                  <a-row justify="center">
                    <a-col :xxl="20" :xs="24">
                      <div class="wizard-side-border">
                        <WizardWrapper>
                          <WizardView>
                            <Steps
                              :current="current"
                              :steps="steps"
                              @onNext="next"
                              @onLater="later"
                              @onDone="next"
                              :status="status"
                              :isFinished="isFinished"
                            >
                              <template #company_details>
                                <BasicFormWrapper class="basic-form-inner">
                                  <div class="atbd-form-checkout">
                                    <a-row justify="center">
                                      <a-col :xs="24">
                                        <div class="create-account-form">
                                          <a-form ref="companyForm" :rules="companyDetRules" :model="companyDetails" name="company_details">
                                            <a-row :gutter="25">
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="company_name" label="Company Name">
                                                  <a-input v-model:value="companyDetails.company_name" placeholder="Enter company name" />
                                                </a-form-item>
                                              </a-col>
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="company_no" label="Company No.">
                                                  <a-input v-model:value="companyDetails.company_no" placeholder="Enter company no." />
                                                </a-form-item>
                                              </a-col>
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="support_email" label="Support Email">
                                                  <a-input type="email" v-model:value="companyDetails.support_email" placeholder="name@gmail.com" />
                                                </a-form-item>
                                              </a-col>
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="website" label="Website">
                                                  <a-input type="url" v-model:value="companyDetails.website" placeholder="Enter website" />
                                                </a-form-item>
                                              </a-col>
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="address" label="Address">
                                                  <a-input v-model:value="companyDetails.address" placeholder="Enter address" />
                                                </a-form-item>
                                              </a-col>
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="city" label="City">
                                                  <a-input v-model:value="companyDetails.city" placeholder="Enter city" />
                                                </a-form-item>
                                              </a-col>
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="zip_code" label="Zipcode">
                                                  <a-input v-model:value="companyDetails.zip_code" placeholder="Enter zipcode" />
                                                </a-form-item>
                                              </a-col>
                                            </a-row>
                                          </a-form>
                                        </div>
                                      </a-col>
                                    </a-row>
                                  </div>
                                </BasicFormWrapper>
                              </template>
                              <!-- <template #smtp_details>
                                <BasicFormWrapper class="basic-form-inner">
                                  <div class="atbd-form-checkout">
                                    <a-row justify="center">
                                      <a-col :xs="24">
                                        <div class="create-account-form">
                                          <a-form ref="smtpForm" :rules="smtpDetRules" :model="smtpDetails" name="smtp_details">
                                            <a-row :gutter="25">
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="smtp_host" label="Host">
                                                  <a-input v-model:value="smtpDetails.smtp_host" placeholder="Enter host" />
                                                </a-form-item>
                                              </a-col>
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="smtp_port" label="Port">
                                                  <a-input type="number" v-model:value="smtpDetails.smtp_port" placeholder="Enter port" />
                                                </a-form-item>
                                              </a-col>
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="smtp_username" label="Username">
                                                  <a-input v-model:value="smtpDetails.smtp_username" placeholder="Enter username" />
                                                </a-form-item>
                                              </a-col>
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="smtp_password" label="password">
                                                  <a-input type="password" v-model:value="smtpDetails.smtp_password" placeholder="Enter password" />
                                                </a-form-item>
                                              </a-col>
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="smtp_from_name" label="From Name">
                                                  <a-input v-model:value="smtpDetails.smtp_from_name" placeholder="Enter from name" />
                                                </a-form-item>
                                              </a-col>
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="smtp_from_email" label="From Email">
                                                  <a-input type="email" v-model:value="smtpDetails.smtp_from_email" placeholder="Enter from email" />
                                                </a-form-item>
                                              </a-col>
                                              <a-col :lg="12" :xxl="12" :xs="24">
                                                <a-form-item name="encryption_type" label="Encryption Type">
                                                  <a-input v-model:value="smtpDetails.encryption_type" placeholder="Enter encryption type" />
                                                </a-form-item>
                                              </a-col>
                                            </a-row>
                                          </a-form>
                                        </div>
                                      </a-col>
                                    </a-row>
                                  </div>
                                </BasicFormWrapper>
                              </template> -->
                            </Steps>
                          </WizardView>
                        </WizardWrapper>
                      </div>
                    </a-col>
                  </a-row>
                </sdCards>
              </WizardBlock>
            </a-col>
          </a-row>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Modal from "@/components/frontmodals/Modal.vue";
import Steps from "@/components/steps/onboarding/steps.vue";
import Notification from "@/services/Notification";

import {
  BasicFormWrapper } from "../../styled";
import {
  WizardView,
  WizardBlock,
  WizardWrapper
} from "./style";
export default {
  name: "OnBoarding",
  components: {
    Modal,
    Steps,
    WizardView,
    WizardBlock,
    WizardWrapper,
    BasicFormWrapper
  },
  computed: {
    authUser: function() {
      return this.$store.getters.authUser;
    },
    // smtp: function() {
    //   return this.$store.getters.smtpDetails;
    // },
    companyForm: function() {
      return this.$refs.companyForm;
    },
    // smtpForm: function() {
    //   return this.$refs.smtpForm;
    // },
  },
  data() {
    return {
      status: "process",
      current: 0,
      isFinished: false,
      steps: [
        {
          title: '',
          content: 'company_details',
          subTitle: 'Company Details',
          description: 'Fillout all your company details here.',
          path: 'img/wizards/1.svg',
        },
        // {
        //   title: '',
        //   content: 'smtp_details',
        //   subTitle: 'SMPT Details',
        //   description: 'Fillout all your SMTP details here.',
        //   path: 'img/wizards/1-1.svg',
        // },
      ],
      companyDetails: {
        company_name: "",
        company_no: "",
        address: "",
        zip_code: "",
        city: "",
        website: "",
        support_email: "",
      },
      // smtpDetails: {
      //   smtp_host: "",
      //   smtp_port: "",
      //   smtp_username: "",
      //   smtp_password: "",
      //   smtp_from_name: "",
      //   smtp_from_email: "",
      //   encryption_type: "",
      // },
      companyDetRules: {
        company_name: [{
          required: true, trigger: 'blur',
          message: 'Please input company name',
        }],
        company_no: [{
          required: true, trigger: 'blur',
          message: 'Please input company no.',
        }],
        address: [{
          required: true, trigger: 'blur',
          message: 'Please input address',
        }],
        zip_code: [{
          required: true, trigger: 'blur',
          message: 'Please input zipcode',
        }, {
          type: 'number', triger: 'blur',
          message: 'Please input valid zipcode', transform: (v) => Number(v)
        }],
        city: [{
          required: true, trigger: 'blur',
          message: 'Please input city',
        }],
        website: [{
          required: true, trigger: 'blur',
          message: 'Please input website',
        }, {
          type: 'url', trigger: 'blur',
          message: 'Please input valid website',
        }],
        support_email: [{
          required: true, trigger: 'blur',
          message: 'Please input support email',
        }, {
          type: 'email', trigger: 'blur',
          message: 'Please input valid support email',
        }],
      },
      // smtpDetRules: {
      //   smtp_host: [{
      //     required: true, trigger: 'blur',
      //     message: 'Please input host',
      //   }],
      //   smtp_port: [{
      //     required: true, trigger: 'blur',
      //     message: 'Please input port',
      //   }, {
      //     type: 'number', triger: 'blur',
      //     message: 'Please input valid port', transform: (v) => Number(v)
      //   }],
      //   smtp_username: [{
      //     required: true, trigger: 'blur',
      //     message: 'Please input username',
      //   }],
      //   smtp_password: [{
      //     required: true, trigger: 'blur',
      //     message: 'Please input password',
      //   }],
      //   smtp_from_name: [{
      //     required: true, trigger: 'blur',
      //     message: 'Please input from name',
      //   }],
      //   smtp_from_email: [{
      //     required: true, trigger: 'blur',
      //     message: 'Please input from email',
      //   }, {
      //     type: 'email', triger: 'blur',
      //     message: 'Please input valid from email'
      //   }],
      //   encryption_type: [{
      //     required: true, trigger: 'blur',
      //     message: 'Please input encryption type',
      //   }],
      // },
    };
  },
  methods: {
    // ...mapActions(["updateProfile", "updateSmtpDetails"]),
    ...mapActions(["updateProfile"]),
    async next() {
      try {
        const { first_name = "", last_name = "", email = "" } = this.authUser;
        await this.companyForm.validate();
        const cData = {
          ...this.companyDetails,
          first_name, last_name, email,
        }

        const { status } = await this.updateProfile(cData);

        if (status) {
          this.$store.commit("setUser", {onboarding: false});
          this.$router.replace({path: "portal/dashboard"});

          // this.status = 'process';
          // this.current = this.current + 1;
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error", title: "Error"});
        }
      }
    },
    later() {
      this.$store.commit("setUser", {onboarding: false});
      this.$router.replace({path: "portal/dashboard"});
    },
    async done() {
      try {
        // await this.smtpForm.validate();
        const { status } = await this.updateSmtpDetails(this.smtpDetails);

        if (status) {
          this.$store.commit("setUser", {onboarding: false});
          this.$router.replace({path: "portal/dashboard"});
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error", title: "Error"});
        }
      }
    }
  },
  mounted() {
    const { company_name = "", company_no = "", address = "", zip_code = "", website = "", city = "", support_email = ""} = this.authUser;
    // const { smtp_host = "", smtp_port = "", smtp_username = "", smtp_password = "", smtp_from_name = "", smtp_from_email = "", encryption_type = "" } = this.smtp;
    this.companyDetails = { company_name, company_no, address, zip_code, city, website, support_email };
    // this.smtpDetails = { smtp_host, smtp_port, smtp_username, smtp_password, smtp_from_name, smtp_from_email, encryption_type }
  },
  created() {
    const { onboarding = false } = this.authUser;
    if (!onboarding) {
      this.$router.replace({path: "portal/dashboard"});
    }
  }
}
</script>